import React from 'react';
import { graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
    IntroQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const RolleReligionGesellschaft = ({ data }) => (
    <InnerPage title="Religion in der Gesellschaft" description="">
        <IntroHolder
            title="Religion in der Gesellschaft"
            subtitle="Möglichkeiten finden, wie Religion zum gesellschaftlichen Zusammenleben beitragen kann"
            picture={data.introImage}
            breadcrumbs={{
                grandparent: 'Gemeinsames Handeln',
                parent: 'Beteiligung an Diskursen',
                'current-item': 'Religion in der Gesellschaft',
            }}
        >
            <IntroQuote>
                Unter Bahá'u'lláhs Lehren finden wir ferner, daß Religion mit
                Wissenschaft und Vernunft in Einklang sein muß, so daß sie auf
                die Menschenherzen wirkt. Die Grundlage muß festgefügt sein und
                darf nicht auf Nachahmung beruhen.
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Religion ist eine Quelle, aus der die Menschheit seit jeher
                    Wissen und Erkenntnisse ziehen kann. Sie verhilft uns zu
                    gesellschaftlichem Zusammenhalt und Frieden und wirkt
                    sinnstiftend. Ein konstruktives Verständnis von Religion
                    richtet den Blick auf Fortschritt und Frieden und erkennt in
                    ihr eine gesellschaftsbildende Kraft.
                </p>
                <div>
                    <p>
                        <img
                            alt=""
                            src={data.plakat1.childImageSharp.fluid.src}
                            className="aligncenter"
                        />
                    </p>
                    <p>
                        Religion setzt eine Kraft frei, die auch
                        konfessionsübergreifend die Gesellschaft beeinflusst.
                        Damit kann Religion Kulturen prägen und wirkt außerdem
                        auf den einzelnen Menschen, indem sie diesen bspw. zum
                        sozialen Engagement motiviert – woraus die ganze
                        Gesellschaft einen Nutzen ziehen kann. Deutschland ist
                        von einer Geschichte der Migration und Integration
                        vieler Religionen geprägt. Wie kann mit der daraus
                        entstandenen Vielfalt der Religionen in Deutschland
                        umgegangen werden? Wie kann diese Vielfalt den
                        gesellschaftlichen Zusammenhalt stärken?
                    </p>
                    <p>
                        <img
                            alt=""
                            src={data.plakat2.childImageSharp.fluid.src}
                            className="aligncenter"
                        />
                    </p>
                    <p>
                        Um dies näher mit anderen zu erforschen, schafft die
                        Bahá’í-Gemeinde in Kooperation mit weiteren
                        Organisationen Räume für einen gemeinsamen Austausch zu
                        der Rolle von Religion in der Gesellschaft. Die dort
                        stattfindenden Gespräche sind ein Teil des in
                        Deutschland fortlaufenden Diskurses zu diesem Thema. Die
                        Teilhabe an diesen Gesprächsformaten sollte immer mehr
                        Menschen ermöglicht werden. Gespeist durch die
                        reichhaltigen Erfahrungen der jeweiligen Teilnehmer
                        erweitert sich das gemeinsame Verständnis und ermöglicht
                        eine Anwendung in diversen Arbeitsgebieten und
                        -umfeldern.
                    </p>
                    <p>
                        Erste Beiträge zu diesem Thema findet man hier zum
                        Download:
                    </p>
                    <p>
                        Dokumentation der Veranstaltung „Religionspluralismus
                        weiter gedacht“ (<a href="/dokumente/2017-Religionspluralismus_Broschuere_web.pdf">PDF</a>, 3.3MB) <br />
                        Dokumentation der Gesprächsreihe zur
                        gesellschaftsbildenden Kraft von Religion (<a href="/dokumente/2017-Gesprächsreihe_Broschuere_web.pdf">PDF</a>, 3MB)
                    </p>
                    <p>
                        <img
                            alt=""
                            src={data.plakat3.childImageSharp.fluid.src}
                            className="aligncenter"
                        />
                    </p>
                </div>
                <Reference>
                    <p>1. ‘Abdu’l-Bahá, Briefe und Botschaften 227</p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default RolleReligionGesellschaft;

export const pageQuery = graphql`
    query {
        introImage: file(relativePath: { eq: "rolle-religion-feature.jpg" }) {
            ...fluidImage
        }
        plakat1: file(relativePath: { eq: "Plakat_A0_Web.png" }) {
            ...fluidImage
        }
        plakat2: file(relativePath: { eq: "Plakat03_Web.png" }) {
            ...fluidImage
        }
        plakat3: file(relativePath: { eq: "Plakat02_Web.png" }) {
            ...fluidImage
        }
    }
`;
